export const generatePdf = (event, eleve, site, urlPdf) => {
  let data = {
    event: event,
    eleve: eleve.user,
    site: site,
    tuteur: eleve.entreprises && eleve.entreprises[0],
  };
  let my_form = document.createElement("form");
  my_form.method = "POST";
  my_form.action = `${urlPdf}/sanction`;
  my_form.target = "_blank";
  my_form.hidden = true;

  let my_tb = document.createElement("input");
  my_tb.type = "textarea";
  my_tb.name = "data";
  my_tb.hidden = true;
  my_tb.value = JSON.stringify(data);
  my_form.appendChild(my_tb);

  document.body.appendChild(my_form);
  my_form.submit();
  document.body.removeChild(my_form);
};