import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GestRetardContext from "../../../Context/GestRetardContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import SmallSpinner from "../../common/SmallSpinner";
import heuresFormH from "../../tools/heuresFormH";
import { autoCloseMsg } from "../../tools/messagesUtils";
import AbsEleveNoSync from "../syncAbsences/AbsEleveNoSync";

const RtdEleveEdusign = ({
  promos,
  connector,
  seance,
  date,
  eventEdusign,
  token,
  periode,
  depart = false,
}) => {
  const { url, user, urlEdusign, connectors } = useContext(AppContext);
  const { retards, departs, setMaj } = useContext(GestRetardContext);

  let retardNocx = depart ? departs : retards;

  const duree = () => {
    if (depart) {
      let tmp =
        new Date(eventEdusign.end) - new Date(eventEdusign.earlyDeparture);
      return heuresFormH(new Date(tmp).toISOString());
    }

    let tmp = new Date(eventEdusign.delay * 60000);
    return heuresFormH(new Date(tmp).toISOString());
  };

  const [eleve, setEleve] = useState(null);
  const [verif, setVerif] = useState(null);
  const [promosEleve, setPromosEleve] = useState(null);
  const [load, setLoad] = useState(false);
  const [saved, setSaved] = useState(true);
  const [msg, setMsg] = useState(null);
  const [retard, setRetard] = useState({
    createdAt: date,
    semestre: null,
    moment: periode,
    justify: false,
    prev: false,
    depart: depart,
    time: duree(),
    motif: eventEdusign.comment,
    edusignId: eventEdusign._id.toString(),
  });

  useEffect(() => {
    if (seance) {
      loadStudent();
    } else {
      loadEleve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eleve !== null && eleve !== undefined && retardNocx !== null) {
      verifExist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eleve, retardNocx]);

  const loadStudent = async () => {
    let uri = urlEdusign.STUDENTS + "/" + eventEdusign.studentId;
    let tmpEleve = "";
    await GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object") {
        let result = res.result;
        if (result !== undefined) {
          tmpEleve = result;
        }
      } else {
        // Message d'erreur
      }
    });
    let tmpEtudiant;
    if (tmpEleve.API_ID) {
      let uriEleve = `${url}api/eleves?`;
      if (connector === connectors.V1) {
        uriEleve += `idGalia=${tmpEleve.API_ID}`;
      }
      if (connector === connectors.V2) {
        uriEleve += `idStagiaire=${tmpEleve.API_ID}`;
      }
      await GetBddProvider(uriEleve).then((res) => {
        if (typeof res === "object" && res["hydra:member"].length > 0) {
          tmpEtudiant = res["hydra:member"].reverse()[0];
        } else {
          tmpEtudiant = false;
          setMsg({
            txt: `Elève non synchronisé dans NOC'x !`,
            type: `danger`,
          });
        }
      });
    } else {
      tmpEtudiant = false;
      setMsg({
        txt: `Elève non créé dans Galia !`,
        type: `danger`,
      });
    }

    if (tmpEtudiant !== false) {
      setEleve(tmpEtudiant);
      setRetard((curr) => {
        return { ...curr, eleve: tmpEtudiant["@id"] };
      });
      await loadPromos(tmpEtudiant.promos);
    }
  };

  const loadEleve = () => {
    // if (!Object.values(connectors).some((v) => v === connector) || !seance) {
    let uri = url + "api/eleves?IdEdusign=" + eventEdusign.studentId;
    GetBddProvider(uri).then(async (res) => {
      if (typeof res === "object" && res["hydra:member"].length > 0) {
        let result = res["hydra:member"].reverse();
        if (result.length > 1) {
          setMsg({
            txt: `Attention ${
              result.length
            } élèves ont le même identifiant Edusign ! ${result.map(
              (r) => r.user.name + " " + r.user.firstName
            )}`,
            type: `danger`,
          });
        }
        setEleve(result[0]);
        setRetard((curr) => {
          return { ...curr, eleve: result[0]["@id"] };
        });
        if (result.length > 0 && promos.length === 0) {
          loadPromos(result[0].promos);
        } else {
          let tmp = promos.find((p) => result[0].promos.includes(p["@id"]));
          let tmpSem =
            tmp.semestres.find((s) => s.actif) ||
            tmp.semestres[tmp.semestres.length - 1];
          setRetard((curr) => {
            return {
              ...curr,
              semestre: tmpSem["@id"],
              promo: tmp["@id"],
            };
          });
          setPromosEleve(() => tmp);
        }
      } else {
        let uriEdusign = urlEdusign.STUDENTS + "/" + eventEdusign.studentId;
        let tmpEleve = null;
        await GetBddProvider(uriEdusign, token).then((res) => {
          if (typeof res === "object") {
            let result = res.result;
            if (result !== undefined) {
              tmpEleve = result;
            }
          } else {
            // Message d'erreur
          }
        });
        setMsg({
          txt: `Elève non synchronisé dans NOC'x (${tmpEleve?.LASTNAME} ${tmpEleve?.FIRSTNAME}), rdv dans le menu sync Edusign !`,
          type: `danger`,
        });
      }
    });
    // } else {
    //   setMsg({
    //     txt: `Session inexistante dans Galia !`,
    //     type: `danger`,
    //   });
    // }
  };

  const verifExist = () => {
    let test = retardNocx.find(
      (a) =>
        a.eleve["@id"] === eleve["@id"] &&
        a.moment === periode &&
        a.createdAt.split("T")[0] === date &&
        a?.depart === depart
    );
    setVerif(test);
    setLoad(true);
  };

  const loadPromos = async (listPromos) => {
    let tmp = [];
    for (const p of listPromos) {
      let result = await GetBddProvider(url + p.slice(1));
      if (typeof result === "object" && result.id && result.actif) {
        tmp.push(result);
      }
    }
    if (tmp.length > 0) {
      let tmpPromo = tmp[0];
      let tmpSem =
        tmpPromo.semestres.find((s) => s.actif) ||
        tmpPromo.semestres[tmp.semestres.length - 1];
      setRetard((curr) => {
        return {
          ...curr,
          semestre: tmpSem["@id"],
          promo: tmpPromo["@id"],
        };
      });
      setPromosEleve(tmpPromo);
    }
  };

  const disableBtn = () => {
    let value = false;
    if (
      retard.semestre === null ||
      retard.semestre === "" ||
      !retard.eleve ||
      !retard.promo
    ) {
      value = true;
    }
    return value;
  };

  const updateRtd = async (element) => {
    setSaved(false);
    let data = {
      edusignId: eventEdusign._id.toString(),
      time: retard.time,
      user: user["@id"],
    };
    let uri = url + element["@id"].slice(1);
    UpdateBddProvider(uri, data).then((res) => {
      setSaved(true);
      if (typeof res === "object" && res.id) {
        setMaj(true);
      } else {
        setMsg({ txt: "erreur lors de la mise à jour !", type: "danger" });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const saveRtd = () => {
    setSaved(false);
    let uri = url + "api/retards";
    let uriTest =
      url +
      "api/retards?eleve=" +
      retard.eleve +
      "&createdAt[after]=" +
      retard.createdAt +
      "&createdAt[before]=" +
      retard.createdAt +
      "&moment=" +
      retard.moment +
      "&depart=" +
      retard.depart;

    GetBddProvider(uriTest).then(async (res) => {
      if (res["hydra:member"].length !== 0) {
        setSaved(true);
        setMaj(true);
      } else {
        PostBddProvider(uri, retard).then((res) => {
          setSaved(true);
          if (typeof res === "object" && res.id) {
            setMaj(true);
          } else {
            setMsg({
              txt: "erreur lors de l'enregistrement' !",
              type: "danger",
            });
            autoCloseMsg(setMsg, 5000);
          }
        });
      }
    });
  };
  if (msg) {
    return (
      <li
        className={`list-group-item list-group-item-${msg.type} ${
          depart ? "border-warning" : "border-danger"
        }`}
      >
        {msg.txt}
      </li>
    );
  }
  if (!load || !promosEleve) {
    return (
      <li className="list-group-item">
        <SmallSpinner />
      </li>
    );
  }

  if (eleve === null) {
    return <AbsEleveNoSync student={eventEdusign} token={token} />;
  }

  if (verif) {
    return verif.edusignId === eventEdusign._id.toString() ? (
      <li
        className={`list-group-item list-group-item-success ${
          depart ? "border-warning" : "border-danger"
        }`}
      >
        {eleve.user.name} {eleve.user.firstName}
        {!verif.motif || verif.motif === ""
          ? " - Pas de motif"
          : ` - ${verif.motif}`}{" "}
        -{" "}
        {depart
          ? `Le départ anticipé est synchronisé !`
          : `Le retard est synchronisée !`}
      </li>
    ) : (
      <li
        className={`list-group-item text-warning ${
          depart ? "border-warning" : "border-danger"
        }`}
      >
        <button
          className="btn btn-sm rounded-pill btn-outline-info mr-4"
          onClick={() => updateRtd(verif)}
          disabled={!saved}
        >
          {saved ? (
            `Synchroniser ${depart ? "le départ" : "le retard"}`
          ) : (
            <SmallSpinner />
          )}
        </button>
        {eleve.user.name} {eleve.user.firstName} - {retard.time} -{" "}
        {!verif.motif || verif.motif === "" ? "Pas de motif" : verif.motif} (
        {promosEleve.semestres.find((s) => s["@id"] === verif.semestre).name})
      </li>
    );
  }

  return (
    <li
      className={`list-group-item form-inline d-flex ${
        depart ? "border-warning" : "border-danger"
      }`}
    >
      <div>
        <strong className="col-form-label">
          {eleve.user.name} {eleve.user.firstName}
        </strong>{" "}
        <small>({promosEleve.name})</small>
      </div>
      <div className="form-group ml-3 mr-1">
        <label>Durée : </label>
        <input
          type="time"
          title={`Durée du ${depart ? "départ" : "retard"}`}
          min="00:01"
          step="60"
          pattern={
            navigator.userAgent.indexOf("Safari") >= 1 &&
            navigator.userAgent.indexOf("Chrome") <= 0
              ? "0[1-4]:\\d{2}"
              : null
          }
          value={retard.time || "00:00"}
          className="form-control ml-1"
          onChange={(e) =>
            setRetard((curr) => {
              return {
                ...curr,
                time: e.target.value,
              };
            })
          }
          required
        />
      </div>
      <div className="form-group ml-3 mr-1">
        <select
          value={retard.semestre || ""}
          className="form-control rounded-pill"
          onChange={(e) =>
            setRetard((curr) => {
              return { ...curr, semestre: e.target.value };
            })
          }
        >
          <option value="" disabled>
            Choisir un semestre
          </option>
          {promosEleve.semestres &&
            promosEleve.semestres.map((s) => (
              <option value={s["@id"]} key={s["@id"]}>
                {s.name}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group mx-1">
        <input
          type="text"
          value={retard.motif || ""}
          placeholder="Motif"
          className="form-control rounded-pill"
          onChange={(e) => {
            let value = e.target.value;
            setRetard((curr) => {
              return { ...curr, motif: value };
            });
          }}
        ></input>
      </div>
      <div className="form-group mx-1">
        <div className="form-check">
          <input
            id={eleve["@id"] + "-" + eventEdusign.ID + "-justify"}
            type="checkbox"
            className="form-check-input"
            checked={retard.justify || ""}
            onChange={() => {
              setRetard((curr) => {
                return { ...curr, justify: !curr.justify };
              });
            }}
          />
          <label htmlFor={eleve["@id"] + "-" + eventEdusign.ID + "-justify"}>
            Justifiée
          </label>
        </div>
      </div>
      <div className="form-group ml-1 mr-3">
        <div className="form-check">
          <input
            id={eleve["@id"] + "-" + eventEdusign.ID + "-prevent"}
            type="checkbox"
            className="form-check-input"
            checked={retard.prev}
            onChange={() => {
              setRetard((curr) => {
                return { ...curr, prev: !curr.prev };
              });
            }}
          />
          <label htmlFor={eleve["@id"] + "-" + eventEdusign.ID + "-prevent"}>
            Prévenue
          </label>
        </div>
      </div>
      <div>
        <button
          className="btn btn-sm rounded-pill btn-outline-info"
          disabled={disableBtn()}
          onClick={() => saveRtd()}
        >
          {saved ? "Enregistrer" : <SmallSpinner />}
        </button>
      </div>
    </li>
  );
};

export default RtdEleveEdusign;
