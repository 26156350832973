import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import dateFormated from "../../tools/dateFormated";
import { generatePdf } from "../../tools/SanctionPDF";

export const ListSanctions = ({
  idAccordion = "accordionSanction",
  sanctions,
  motifs,
  setSanctionSelected,
  deleteSanction,
  eleve,
  user,
  show = false,
  titre = "Vie Scolaire",
  site,
}) => {
  const { urlPdf } = useContext(AppContext);

  let contentID = `${idAccordion}Content`;
  let headingID = `heading${idAccordion}`;

  // const generatePdf = (event) => {
  //   let data = {
  //     event: event,
  //     eleve: eleve.user,
  //     site: site,
  //     tuteur: eleve.entreprises[0],
  //   };
  //   let my_form = document.createElement("form");
  //   my_form.method = "POST";
  //   my_form.action = `${urlPdf}/sanction`;
  //   my_form.target = "_blank";
  //   my_form.hidden = true;

  //   let my_tb = document.createElement("input");
  //   my_tb.type = "textarea";
  //   my_tb.name = "data";
  //   my_tb.hidden = true;
  //   my_tb.value = JSON.stringify(data);
  //   my_form.appendChild(my_tb);

  //   document.body.appendChild(my_form);
  //   my_form.submit();
  //   document.body.removeChild(my_form);
  // };

  return (
    <div className="accordion" id={idAccordion}>
      <div className="card border-primary">
        <div className="card-header p-0" id={headingID}>
          <h2 className="text-center text-primary">
            <button
              className="btn btn-link btn-block text-decoration-none font-weight-bold"
              type="button"
              data-toggle="collapse"
              data-target={`#${contentID}`}
              aria-expanded={show}
              aria-controls={contentID}
            >
              {titre} ({sanctions.length}{" "}
              {sanctions.length > 1 ? "événements" : "événement"}){" "}
              <FontAwesomeIcon icon="chevron-down" />
            </button>
          </h2>
        </div>
        <div
          id={contentID}
          className={`collapse ${show && "show"}`}
          aria-labelledby={headingID}
          data-parent={`#${idAccordion}`}
        >
          <ul className="list-group list-group-flush">
            {sanctions.length < 1 ? (
              <li className="list-group-item text-left">
                {" "}
                <h6 className="text-primary text-center">
                  Pas d'événement !
                </h6>{" "}
              </li>
            ) : (
              motifs.map((motif) => {
                let sanctionsFiltered = sanctions.filter(
                  (s) => s.motif["@id"] === motif["@id"]
                );
                if (sanctionsFiltered.length < 1) return null;
                return (
                  <React.Fragment key={motif["@id"]}>
                    <h6 className="text-left text-primary mx-3 mt-3 mb-0">
                      {motif.name}
                    </h6>
                    {sanctionsFiltered.map((sanction) => (
                      <li
                        className="list-group-item text-left font-small"
                        key={sanction["@id"]}
                      >
                        {user.role < 4 &&
                          !user.roles.includes("ROLE_ELEVE") && (
                            <>
                              <FontAwesomeIcon
                                className="text-primary mr-1"
                                style={{ cursor: "pointer" }}
                                data-toggle="modal"
                                data-target="#ModalEditSanction"
                                title="Modifier l'évènement'"
                                onClick={() =>
                                  setSanctionSelected({
                                    ...sanction,
                                    eleve: sanction.eleve["@id"],
                                    author: sanction.author["@id"],
                                    motif: sanction.motif["@id"],
                                    createdAt: sanction.createdAt.split("T")[0],
                                  })
                                }
                                icon="edit"
                              />
                              <FontAwesomeIcon
                                className="text-danger mr-1"
                                style={{ cursor: "pointer" }}
                                title="Supprimer l'évènement"
                                onClick={() =>
                                  window.confirm(
                                    "Voulez-vous supprimer cet'évènement ?"
                                  ) && deleteSanction(sanction["@id"])
                                }
                                icon="trash-alt"
                              />
                              <FontAwesomeIcon
                                className="text-success mr-2"
                                style={{ cursor: "pointer" }}
                                title="Générer le pdf"
                                onClick={() =>
                                  generatePdf(sanction, eleve, site, urlPdf)
                                }
                                icon="print"
                              />
                            </>
                          )}

                        <span className="font-weight-bold">
                          <u>
                            {dateFormated(sanction.createdAt)} -{" "}
                            {sanction.author.firstName} {sanction.author.name} :
                          </u>
                        </span>
                        <br />
                        <span className="font-italic">
                          <u>commentaire</u> :{" "}
                          {sanction.comment.split("\n").map((c, i) => (
                            <React.Fragment key={i}>
                              {c}
                              <br />
                            </React.Fragment>
                          ))}
                        </span>
                        {user.role < 4 &&
                          !user.roles.includes("ROLE_ELEVE") &&
                          sanction.notes && (
                            <span className="text-muted font-italic">
                              <u>notes</u> :{" "}
                              {sanction.notes.split("\n").map((c, i) => (
                                <React.Fragment key={i}>
                                  {c}
                                  <br />
                                </React.Fragment>
                              ))}
                            </span>
                          )}
                      </li>
                    ))}
                  </React.Fragment>
                );
              })
            )}
          </ul>
          {user.role < 4 && !user.roles.includes("ROLE_ELEVE") && (
            <button
              className="btn btn-primary my-2"
              data-toggle="modal"
              data-target="#ModalEditSanction"
              onClick={() =>
                setSanctionSelected({
                  eleve: eleve["@id"],
                  createdAt: new Date().toISOString().split("T")[0],
                  author: user["@id"],
                })
              }
            >
              Ajouter un évènement
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
